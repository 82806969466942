import * as React from 'react'
import Nav from "../components/nav"
import Footer from "../components/footer"
import Hero from "../components/hero"
import {Helmet} from "react-helmet"
import OpenGraphLogo from "../images/open-graph-logo.png"
import Imagevolleyball from "../images/volleyball-page.jpeg";

function VolleyballPerformance() {
    return (
        <div>
            <Helmet>
                <title>Volleyball Performance & Injury Care | Kinetic Impact</title>
                <meta property="og:title" content="Volleyball Performance & Injury Care | Kinetic Impact" />
                <meta property="og:image" content={OpenGraphLogo} />
                <meta property="og:description" content="Specialized volleyball injury treatment, performance enhancement, and prevention care. Expert rehabilitation for knee injuries, shoulder care, and strength training for optimal jumping performance." />
                <meta name="description" content="Comprehensive volleyball injury treatment and prevention with professional rehabilitation services. Our specialists help players recover from injuries, prevent future problems, and enhance performance through proper strength training." />
                <meta name="keywords" content="volleyball injuries, volleyball physiotherapy, volleyball performance, knee injury treatment, shoulder rehabilitation, jump training, volleyball injury prevention, sports chiropractic" />
            </Helmet>
            <Nav/>
            <Hero href={"https://kineticimpact.janeapp.com/#/1st-timers"} 
                  header={"Expert Volleyball Injury Care & Performance Enhancement"}>
                <div id={"content-start"} className={"flex flex-col justify center mx-auto px-4 lg:pt-8 pt-4 text-lg bg-white rounded-xl"}>
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        Don't let injuries keep you off the court. Our specialized team combines chiropractic care and physiotherapy to help volleyball players recover from injuries and perform at their best.
                    </p>
                    
                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                        From treating common volleyball injuries to enhancing jumping performance through targeted strength training, our experts understand the unique demands of volleyball and provide comprehensive care for players at all levels.
                    </p>

                    <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto w-full"}>
                        Let's work together to keep you healthy and performing at your peak throughout the season.
                    </p>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Book Your Assessment Today!
                        </button>
                    </a>

                    <div className={"lg:w-1/2 w-full h-fit pt-12 mx-auto"}>
                        <img className={"rounded"} src={Imagevolleyball} alt="Movement screening analysis"/>
                    </div>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Comprehensive Volleyball Care</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Injury Assessment & Treatment</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Get expert treatment for common volleyball injuries including jumper's knee, shoulder impingement, and ankle sprains. Our comprehensive assessment identifies the root cause of your pain and develops an effective treatment plan.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Performance Enhancement</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Improve your vertical jump and overall performance through specialized strength training programs. Our approach focuses on building power while maintaining joint health through proper conditioning and technique.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Injury Prevention</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Prevent common volleyball injuries through proper movement assessment and corrective exercises. Our prevention programs focus on strengthening vulnerable areas and maintaining optimal joint health during intense training periods.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Recovery Optimization</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Learn effective strategies for recovery between games and tournaments. Our comprehensive approach includes proper recovery techniques, mobility work, and load management to keep you performing at your best.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10"}>
                            Schedule Your Visit
                        </button>
                    </a>

                    <h2 className={"lg:text-5xl text-4xl text-center pt-16 pb-10"}>Specialized Treatment Areas</h2>

                    <div className={"flex flex-col justify-center"}>
                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Knee Care & Prevention</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Comprehensive treatment and prevention for common volleyball knee injuries. Our specialists address patellar tendinitis, ACL strain, and other knee conditions while developing programs to enhance jumping power safely.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Shoulder Rehabilitation</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Expert care for volleyball shoulder injuries and prevention. We assess spike mechanics from an injury prevention perspective and provide specialized treatment for rotator cuff issues and shoulder impingement.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Movement Assessment</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Detailed analysis of your movement patterns to identify potential injury risks. Our team provides corrective exercises and treatment to address biomechanical issues before they lead to injuries.
                        </p>

                        <h3 className={"text-center text-3xl pb-4 pt-4"}>Performance Recovery</h3>
                        <p className={"lg:pt-4 pb-4 max-w-5xl lg:px-4 mx-auto"}>
                            Customized recovery protocols to maintain peak performance throughout the season. We'll help you develop effective strategies for managing training load and optimizing recovery between matches.
                        </p>
                    </div>

                    <a className={"mx-auto"} href={"https://kineticimpact.janeapp.com/#/1st-timers"}>
                        <button className={"rounded bg-accent hover:brightness-75 px-8 py-4 text-xl text-center mt-10 mb-4"}>
                            Get Back in the Game
                        </button>
                    </a>
                </div>
            </Hero>
            <Footer/>
        </div>
    )
}

export default VolleyballPerformance